import BackLayout from '@/views/back/LayoutView'
const WLSJ = [
  {
    path: '/memberindex',
    component: BackLayout,
    meta: {
      keys: ['memberindex']
    },
    children: [
      {
        path: '',
        meta: {
          authorities: 'login',
          title: '后台首页',
          icon: 'icon-home',
          keys: ['memberindex']
        },
        component: () => import(/* webpackChunkName: "memberindex" */ '@/views/back/dashboard/wlsj/IndexView')
      }
    ]
  },
  {
    path: '/viraccountset',
    component: BackLayout,
    meta: {
      title: '个人账户设置',
      icon: 'icon-Union-5',
      keys: ['viraccountset']
    },
    children: [
      {
        path: 'accbaseinfo',
        meta: {
          authorities: 'login',
          title: '个人基本信息',
          keys: ['accbaseinfo']
        },
        component: () => import(/* webpackChunkName: "viraccountset-accbaseinfo" */ '@/views/person/basexx/IndexView')
      },
      {
        path: 'cardxx',
        meta: {
          title: '身份基本信息',
          activeMenu: '/viraccountset/accbaseinfo',
          keys: ['accbaseinfo'],
          authorities: 'login'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "viraccountset-cardxx" */ '@/views/person/basexx/IDCardView')
      },
      {
        path: 'mobmgr',
        meta: {
          title: '修改手机号',
          activeMenu: '/viraccountset/accbaseinfo',
          keys: ['accbaseinfo'],
          authorities: 'login'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "viraccountset-mobmgr" */ '@/views/person/basexx/phone/IndexView')
      },
      {
        path: 'myrzmgr',
        meta: {
          authorities: 'login',
          title: '我的认证',
          keys: ['myrzmgr']
        },
        component: () => import(/* webpackChunkName: "viraccountset-myrzmgr" */ '@/views/person/certification/ApplyView')
      },
      {
        path: 'person',
        meta: {
          title: '实名认证',
          activeMenu: '/viraccountset/myrzmgr',
          keys: ['myrzmgr'],
          authorities: 'login'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "viraccountset-person" */ '@/views/person/certification/person/IndexView')
      },
      {
        path: 'transunchk',
        meta: {
          title: '物流世家认证',
          authorities: 'login',
          activeMenu: '/viraccountset/myrzmgr',
          keys: ['myrzmgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "viraccountset-transunchk" */ '@/views/person/certification/wlsj/UnChkView')
      },
      {
        path: 'transport',
        meta: {
          title: '物流世家认证',
          authorities: 'login',
          activeMenu: '/viraccountset/myrzmgr',
          keys: ['myrzmgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "viraccountset-transport" */ '@/views/person/certification/wlsj/IndexView')
      },
      {
        path: 'transchk',
        meta: {
          title: '物流世家认证',
          authorities: 'login',
          activeMenu: '/viraccountset/myrzmgr',
          keys: ['myrzmgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "viraccountset-transchk" */ '@/views/person/certification/wlsj/ChkView')
      },
      {
        path: 'modpassword',
        meta: {
          authorities: 'login',
          title: '修改密码',
          keys: ['modpassword']
        },
        component: () => import(/* webpackChunkName: "viraccountset-modpassword" */ '@/views/person/password/IndexView')
      }
    ]
  },
  {
    path: '/myorders',
    component: BackLayout,
    meta: {
      keys: ['myorders'],
      title: '订单管理中心'
    },
    children: [
      {
        path: '',
        name: 'WlsjMyOrderMgr',
        meta: {
          authorities: 'login',
          title: '订单管理中心',
          breadcrumb: false,
          keys: ['myorders'],
          icon: 'icon-Union-2'
        },
        component: () => import(/* webpackChunkName: "myorders" */ '@/views/back/myorders/wlsj/list/IndexView')
      },
      {
        path: 'detail',
        meta: {
          authorities: 'login',
          title: '查看详情',
          keys: ['myorders'],
          activeMenu: '/myorders'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "myorders-detail" */ '@/views/back/myorders/detail/IndexView')
      },
      {
        path: 'sendcar',
        meta: {
          authorities: 'login',
          title: '我要派车',
          keys: ['myorders'],
          activeMenu: '/myorders'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "myorders-sendcar" */ '@/views/back/myorders/wlsj/sendcar/IndexView')
      },
      {
        path: 'resendcar',
        meta: {
          authorities: 'login',
          title: '我要改派',
          keys: ['myorders'],
          activeMenu: '/myorders'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "myorders-resendcar" */ '@/views/back/myorders/wlsj/resendcar/IndexView')
      },
      {
        path: 'addcar',
        meta: {
          authorities: 'login',
          title: '添加车辆',
          keys: ['myorders'],
          activeMenu: '/myorders'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "myorders-addcar" */ '@/views/back/myorders/wlsj/addcar/IndexView')
      }
    ]
  },
  {
    path: '/virtruckmgr',
    redirect: '/virtruckmgr/truckmgr',
    component: BackLayout,
    meta: {
      keys: ['virtruckmgr'],
      title: '车辆管理中心',
      icon: 'icon-cheliangguanli'
    },
    children: [
      {
        path: 'truckmgr',
        meta: {
          authorities: 'login',
          title: '车辆台帐',
          keys: ['truckmgr']
        },
        component: () => import(/* webpackChunkName: "virtruckmgr-truckmgr" */ '@/views/back/truck/wlsj/list/IndexView')
      },
      {
        path: 'edit/:regtkid',
        meta: {
          authorities: 'login',
          title: '编辑车辆',
          activeMenu: '/virtruckmgr/truckmgr',
          keys: ['truckmgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "virtruckmgr-edit" */ '@/views/back/truck/wlsj/edit/IndexView')
      },
      {
        path: 'retj/:tkappid',
        meta: {
          authorities: 'login',
          title: '重新提交',
          activeMenu: '/virtruckmgr/truckmgr',
          keys: ['truckmgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "virtruckmgr-edit" */ '@/views/back/truck/wlsj/edit/IndexView')
      },
      {
        path: 'add',
        meta: {
          authorities: 'login',
          title: '新增车辆',
          activeMenu: '/virtruckmgr/truckmgr',
          keys: ['truckmgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "virtruckmgr-add" */ '@/views/back/truck/wlsj/edit/IndexView')
      },
      {
        path: 'realpos',
        meta: {
          authorities: 'login',
          title: '最新位置查询',
          keys: ['realpos']
        },
        component: () => import(/* webpackChunkName: "realpos" */ '@/views/back/realpos/IndexView')
      },
      {
        path: 'histrack',
        meta: {
          authorities: 'login',
          title: '历史轨迹查询',
          keys: ['histrack']
        },
        component: () => import(/* webpackChunkName: "histrack" */ '@/views/back/histrack/IndexView')
      }
    ]
  },
  {
    path: '/truckmgr',
    redirect: '/virtruckmgr/truckmgr',
    meta: {
      keys: ['truckmgr'],
      title: '车辆管理中心'
    },
    hidden: true,
    component: BackLayout,
    children: [
      {
        path: 'detail',
        redirect: '/virtruckmgr/truckmgr',
        meta: {
          authorities: 'login',
          title: '车辆台账',
          activeMenu: '/virtruckmgr/truckmgr',
          keys: ['truckmgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "truckmgr-detail" */ '@/views/back/truck/detail/IndexView'),
        children: [
          {
            path: 'base',
            meta: {
              authorities: 'login',
              title: '基本信息',
              activeMenu: '/virtruckmgr/truckmgr',
              keys: ['truckmgr']
            },
            component: () => import(/* webpackChunkName: "truckmgr-detail-base" */ '@/views/back/truck/detail/base/IndexView')
          },
          {
            path: 'vehlic',
            meta: {
              authorities: 'login',
              title: '行驶证',
              activeMenu: '/virtruckmgr/truckmgr',
              keys: ['truckmgr']
            },
            component: () => import(/* webpackChunkName: "truckmgr-detail-vehlic" */ '@/views/back/truck/detail/vehlic/IndexView')
          },
          {
            path: 'roadlic',
            meta: {
              authorities: 'login',
              title: '道路运输证',
              activeMenu: '/virtruckmgr/truckmgr',
              keys: ['truckmgr']
            },
            component: () => import(/* webpackChunkName: "truckmgr-detail-roadlic" */ '@/views/back/truck/detail/roadlic/IndexView')
          },
          {
            path: 'jyxkz',
            meta: {
              authorities: 'login',
              title: '道路运输经营许可证',
              activeMenu: '/virtruckmgr/truckmgr',
              keys: ['truckmgr']
            },
            component: () => import(/* webpackChunkName: "truckmgr-detail-jyxkz" */ '@/views/back/truck/detail/jyxkz/IndexView')
          }
        ]
      }
    ]
  },
  {
    path: '/tksrcmgr',
    component: BackLayout,
    meta: {
      keys: ['tksrcmgr'],
      title: '车源管理中心'
    },
    children: [
      {
        path: '',
        meta: {
          authorities: 'login',
          title: '车源管理中心',
          icon: 'icon-cheyuanguanli',
          breadcrumb: false,
          keys: ['tksrcmgr']
        },
        component: () => import(/* webpackChunkName: "tksrcmgr" */ '@/views/back/tksrc/list/IndexView')
      },
      {
        path: 'add',
        meta: {
          authorities: 'login',
          title: '发布车源',
          activeMenu: '/tksrcmgr',
          keys: ['tksrcmgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "tksrcmgr-add" */ '@/views/back/tksrc/edit/IndexView')
      },
      {
        path: 'edit/:tksrcid',
        meta: {
          authorities: 'login',
          title: '编辑车源',
          activeMenu: '/tksrcmgr',
          keys: ['tksrcmgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "tksrcmgr-edit" */ '@/views/back/tksrc/edit/IndexView')
      }
    ]
  },
  {
    path: '/bjsrcmgr',
    component: BackLayout,
    meta: {
      keys: ['biddingjgmgr'],
      title: '报价管理中心'
    },
    children: [
      {
        path: '',
        name: 'QuoteListMgr',
        meta: {
          authorities: 'login',
          title: '报价管理中心',
          breadcrumb: false,
          icon: 'icon-Union1',
          keys: ['biddingjgmgr']
        },
        component: () => import(/* webpackChunkName: "gdsrcmgr" */ '@/views/back/quote/list/IndexView')
      }
    ]
  },
  {
    path: '/virjsmgr',
    component: BackLayout,
    meta: {
      keys: ['virjsmgr'],
      title: '结算管理中心',
      icon: 'icon-bulujiesuan'
    },
    alwaysShow: true,
    children: [
      // {
      //   path: 'reconciliation',
      //   name: 'ReconciliationComponent',
      //   meta: {
      //     authorities: 'login',
      //     title: '对账管理',
      //     keys: ['dzordermgr']
      //   },
      //   component: () => import(/* webpackChunkName: "reconciliation" */ '@/views/back/reconciliation/wlsj/list/IndexView')
      // },
      {
        path: 'recon-order-list',
        name: 'ReconOrderList',
        meta: {
          authorities: 'login',
          title: '订单台账',
          keys: ['dzordermgr']
        },
        component: () => import(/* webpackChunkName: "recon-order-list" */ '@/views/back/reconciliation/new/wlsj/orderlist/IndexView')
      },
      {
        path: 'recon-order-list-detail',
        meta: {
          authorities: 'login',
          title: '订单台账详情',
          keys: ['dzordermgr'],
          activeMenu: '/virjsmgr/recon-order-list'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "recon-new-detail" */ '@/views/back/reconciliation/new/wlsj/orderlist/DetailView')
      },
      {
        path: 'reconciliation-new',
        name: 'ReconciliationNew',
        meta: {
          authorities: 'login',
          title: '对账管理',
          keys: ['dzordermgr']
        },
        component: () => import(/* webpackChunkName: "reconciliation-new" */ '@/views/back/reconciliation/new/wlsj/list/IndexView')
      },
      {
        path: 'recon-new-detail',
        meta: {
          authorities: 'login',
          title: '对账详情',
          keys: ['dzordermgr'],
          activeMenu: '/virjsmgr/reconciliation-new'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "recon-new-detail" */ '@/views/back/reconciliation/new/wlsj/list/DetailView')
      },
      {
        path: 'reconciliation-detail',
        meta: {
          authorities: 'login',
          title: '对账详情',
          keys: ['dzordermgr'],
          activeMenu: '/virjsmgr/reconciliation'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "reconciliation-detail" */ '@/views/back/reconciliation/wlsj/detail/IndexView')
      },
      {
        path: 'reconciliation-detail2',
        meta: {
          authorities: 'login',
          title: '对账详情',
          keys: ['dzordermgr'],
          activeMenu: '/virjsmgr/reconciliation'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "reconciliation-detail2" */ '@/views/back/reconciliation/wlsj/detail/DetailView')
      },
      {
        path: 'yzfskapp',
        name: 'RevApplyOrderMgr',
        meta: {
          authorities: 'login',
          title: '收款申请',
          keys: ['yzfskapp']
        },
        component: () => import(/* webpackChunkName: "ordersignmgr" */ '@/views/back/jsapply/revapply/IndexView')
      },
      {
        path: 'ordersignmgr',
        meta: {
          authorities: 'login',
          title: '签收管理',
          keys: ['ordersignmgr']
        },
        component: () => import(/* webpackChunkName: "ordersignmgr" */ '@/views/back/ordersign/wlsj/IndexView')
      },
      {
        path: 'ordersigndetail',
        meta: {
          authorities: 'login',
          title: '查看详情',
          keys: ['ordersignmgr'],
          activeMenu: '/virjsmgr/ordersignmgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "ordersigndetail" */ '@/views/back/ordersign/wlsj/detail/IndexView')
      },
      {
        path: 'yfjsmgr',
        meta: {
          authorities: 'login',
          title: '运费结算',
          keys: ['yfjsmgr']
        },
        component: () => import(/* webpackChunkName: "yfjsmgr" */ '@/views/back/yfjs/wlsj/list/IndexView')
      },
      {
        path: 'yfjsapply',
        meta: {
          authorities: 'login',
          title: '发起运费结算申请',
          keys: ['yfjsmgr'],
          activeMenu: '/virjsmgr/yfjsmgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "yfjsapply" */ '@/views/back/yfjs/wlsj/apply/IndexView')
      },
      {
        path: 'yfjsreapply',
        meta: {
          authorities: 'login',
          title: '重新提交申请',
          keys: ['yfjsmgr'],
          activeMenu: '/virjsmgr/yfjsmgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "yfjsreapply" */ '@/views/back/yfjs/wlsj/reapply/IndexView')
      },
      {
        path: 'yfjsdetail',
        meta: {
          authorities: 'login',
          title: '查看详情',
          keys: ['yfjsmgr'],
          activeMenu: '/virjsmgr/yfjsmgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "yfjsdetail" */ '@/views/back/yfjs/wlsj/detail/IndexView')
      }
    ]
  },
  {
    path: '/vircapmgr',
    component: BackLayout,
    redirect: '/vircapmgr/mmtwallet',
    meta: {
      title: '资金管理中心',
      icon: 'icon-Vector1',
      keys: ['vircapmgr']
    },
    alwaysShow: true,
    children: [
      {
        path: 'mmtwallet',
        meta: {
          authorities: 'login',
          title: '默默账簿',
          keys: ['mmtwallet']
        },
        component: () => import(/* webpackChunkName: "vircapmgr-mmtwallet" */ '@/views/person/wallet/wlsj/IndexView')
      },
      {
        path: 'walletlist',
        meta: {
          authorities: 'login',
          title: '用户信息查询',
          keys: ['mmtwallet'],
          activeMenu: '/vircapmgr/mmtwallet'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "vircapmgr-walletlist" */ '@/views/person/wallet/yyplat/customer/list/IndexView')
      },
      {
        path: 'tkmayermgr',
        meta: {
          authorities: 'login',
          title: '车队长管理',
          keys: ['tkmayermgr']
        },
        component: () => import(/* webpackChunkName: "vircapmgr-mmtwallet" */ '@/views/back/captain/wlsj/IndexView')
      },
      {
        path: 'addperson',
        meta: {
          authorities: 'login',
          title: '添加车队长',
          keys: ['tkmayermgr'],
          activeMenu: '/vircapmgr/tkmayermgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "vircapmgr-walletlist" */ '@/views/back/captain/wlsj/addperson/IndexView')
      },
      {
        path: 'tkmayerwallet',
        meta: {
          authorities: 'login',
          title: '车队长账簿',
          keys: ['tkmayermgr'],
          activeMenu: '/vircapmgr/tkmayermgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "vircapmgr-mmtwallet" */ '@/views/person/wallet/wlsj/captain/IndexView')
      }
    ]
  },
  {
    path: '/collect',
    component: BackLayout,
    meta: {
      title: '收藏管理中心'
    },
    hidden: true,
    children: [
      {
        path: 'tklist',
        meta: {
          authorities: 'login',
          title: '车源收藏',
          activeMenu: '/memberindex'
        },
        component: () => import(/* webpackChunkName: "collect-tklist" */ '@/views/back/collect/tklist/IndexView')
      },
      {
        path: 'gdlist',
        meta: {
          authorities: 'login',
          title: '货源收藏',
          activeMenu: '/memberindex'
        },
        component: () => import(/* webpackChunkName: "collect-gdlist" */ '@/views/back/collect/gdlist/IndexView')
      }
    ]
  },
  {
    path: '/virsysmgr',
    component: BackLayout,
    meta: {
      keys: ['virsysmgr'],
      title: '系统管理中心',
      icon: 'icon-Union'
    },
    alwaysShow: true,
    children: [
      {
        path: 'deptmgr',
        meta: {
          authorities: 'login',
          title: '部门管理',
          keys: ['deptmgr']
        },
        component: () => import(/* webpackChunkName: "virsysmgr-deptmgr" */ '@/views/back/department/IndexView')
      },
      {
        path: 'staffmgr',
        meta: {
          authorities: 'login',
          title: '员工账号管理',
          keys: ['staffmgr']
        },
        component: () => import(/* webpackChunkName: "virsysmgr-staffmgr" */ '@/views/back/compuser/IndexView')
      },
      {
        path: 'flowmgr',
        meta: {
          authorities: 'login',
          title: '流程管理',
          keys: ['flowmgr']
        },
        component: () => import(/* webpackChunkName: "virsysmgr-flowmgr" */ '@/views/back/flow/IndexView')
      },
      {
        path: 'newmgr',
        meta: {
          authorities: 'login',
          title: '资讯管理',
          keys: ['newmgr']
        },
        component: () => import(/* webpackChunkName: "virsysmgr-newmgr" */ '@/views/back/news/list/IndexView')
      },
      {
        path: 'pubnews',
        meta: {
          title: '发布资讯',
          authorities: 'login',
          keys: ['newmgr'],
          activeMenu: '/virsysmgr/newmgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "pubnews" */ '@/views/back/news/publish/IndexView')
      },
      {
        path: 'editnews',
        meta: {
          title: '编辑资讯',
          authorities: 'login',
          keys: ['newmgr'],
          activeMenu: '/virsysmgr/newmgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "editnews" */ '@/views/back/news/detail/IndexView')
      }
    ]
  },
  {
    path: '/regyjunionai',
    component: BackLayout,
    meta: {
      keys: ['regyjunionai'],
      title: '跨区域物流联动AI'
    },
    children: [
      {
        path: '',
        meta: {
          authorities: 'login',
          title: '跨区域物流联动AI',
          breadcrumb: false,
          icon: 'icon-quyuyunjia',
          keys: ['regyjunionai']
        },
        component: () => import(/* webpackChunkName: "regyjunionai-" */ '@/views/back/aiworkbook/publist/IndexView')
      },
      {
        path: 'pushlist',
        meta: {
          authorities: 'login',
          title: '推送台账',
          keys: ['regyjunionai'],
          activeMenu: '/regyjunionai'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "regyjunionai-pushlist" */ '@/views/back/aiworkbook/pushlist/IndexView')
      }
    ]
  }
]
export default WLSJ
